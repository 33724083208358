import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import { HeaderContextProvider } from "./context/HeaderContext";
// import Header from "./components/common/Header";
// import Footer from "./components/common/Footer";
// import Home from "./pages/Home";
// import Blog from "./pages/Blog";
// import Company from "./pages/Company";
// import TermsCondition from "./components/others/TermsCondition";
// import PrivacyPolicy from "./components/others/PrivacyPolicy";
// import AboutUs from "./components/others/About us/AboutUs";
// import PrivacyPolicyForm from "./components/others/PrivacyPolicyForm";
// import NewRanking from "./pages/NewRanking";
// import NewCategory from "./pages/NewCategory";
// import nPartnerWithUs from "./pages/PartnerWithUs";
// import NotFound from "./pages/NotFound";
// import BlogHome from "./pages/BlogHome";
// import BlogCategory from "./pages/BlogCategory";
// import PartnerWithUs from "./pages/PartnerWithUs";
// import Login from "./pages/Login";
import { auth } from "./firebase/firebase";
import Loader from "./components/common/Loader";

const Header = lazy(() => import("./components/common/Header"));
const Footer = lazy(() => import("./components/common/Footer"));
const Home = lazy(() => import("./pages/Home"));
const Blog = lazy(() => import("./pages/Blog"));
const Company = lazy(() => import("./pages/Company"));
const TermsCondition = lazy(() => import("./components/others/TermsCondition"));
const PrivacyPolicy = lazy(() => import("./components/others/PrivacyPolicy"));
const AboutUs = lazy(() => import("./components/others/About us/AboutUs"));
const PrivacyPolicyForm = lazy(() =>
  import("./components/others/PrivacyPolicyForm")
);
const NewRanking = lazy(() => import("./pages/NewRanking"));
const NewCategory = lazy(() => import("./pages/NewCategory"));
const Login = lazy(() => import("./pages/Login"));
const NotFound = lazy(() => import("./pages/NotFound"));
const BlogHome = lazy(() => import("./pages/BlogHome"));
const BlogCategory = lazy(() => import("./pages/BlogCategory"));
const PartnerWithUs = lazy(() => import("./pages/PartnerWithUs"));

const Layout = () => {
  const currentLocation = useLocation().pathname;
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuthState = async () => {
      await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
          setUser(authUser);
          resolve();
        });

        return () => {
          unsubscribe();
        };
      });
      setLoading(false);
    };

    checkAuthState();
  }, []);

  useEffect(() => {
    const userFromSessionStorage = sessionStorage.getItem("user");

    if (!userFromSessionStorage && !loading && user) {
      navigate("/login");
    }
  }, [user, loading]);

  useEffect(() => {
    if (!user && !loading) {
      navigate("/login");
    }
  }, [user, loading, currentLocation, navigate]);

  return (
    <HeaderContextProvider>
      <div className="font-body">
        {currentLocation !== "/login" && <Header />}
        <Outlet />
        {currentLocation !== "/login" && <Footer />}
      </div>
      {/* <div className="font-body">
        <Header />
        <Outlet />
        <Footer />
      </div> */}
    </HeaderContextProvider>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/categorias/:service",
        element: <NewRanking />,
      },
      {
        path: "/blog/articulos/:category/:blog",
        element: <Blog />,
      },
      {
        path: "/:service/:company",
        element: <Company />,
      },
      {
        path: "/terminos-de-uso",
        element: <TermsCondition />,
      },
      {
        path: "/politica-de-privacidad",
        element: <PrivacyPolicy />,
      },
      {
        path: "/acerca-de-nosotros",
        element: <AboutUs />,
      },
      {
        path: "/formulario-de-politica-de-privacidad",
        element: <PrivacyPolicyForm />,
      },
      {
        path: "/categorias",
        element: <NewCategory />,
      },
      {
        path: "/asociarse",
        element: <PartnerWithUs />,
      },
      {
        path: "/blog",
        element: <BlogHome />,
      },
      {
        path: "/blog/categorias/:category",
        element: <BlogCategory />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

function App() {
  return (
    <Suspense
      fallback={
        <div className="flex justify-center align-middle items-center min-h-screen">
          <Loader />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
