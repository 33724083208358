import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const HeaderContext = createContext();

export const HeaderContextProvider = ({ children }) => {
  const currentLocation = useLocation();
  const [blogHeader, setBlogHeader] = useState(false);
  const location =
    currentLocation.pathname.split("/").includes("articulos") ||
    currentLocation.pathname.split("/").includes("asociarse") ||
    (currentLocation.pathname.includes("blog") &&
      !currentLocation.pathname.includes("categorias"));

  useEffect(() => {
    setBlogHeader(location);
  }, [location]);
  return (
    <HeaderContext.Provider value={{ blogHeader }}>
      {children}
    </HeaderContext.Provider>
  );
};
