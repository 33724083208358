import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDcN-QGC1yCa1SFT4WFwZaYCLzGSf8xsuI",
  authDomain: "mejor-empresa.firebaseapp.com",
  projectId: "mejor-empresa",
  storageBucket: "mejor-empresa.appspot.com",
  messagingSenderId: "1027663407188",
  appId: "1:1027663407188:web:aa7f5cc692db01fa7765c1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
